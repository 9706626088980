// import messages from a json file

import messages from './locales';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'us',
  fallbackLocale: ['es', 'us'],
  messages,
  fallbackWarn: false,
  missingWarn: false,
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
        useGrouping: true
      }
    },
    es: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
        useGrouping: true
      }
    }
  }
}));
